<template>
  <div class="login">
    <h2>Reset password</h2>
    <form @submit.prevent="resetPassword">
      <div class="form-group">
        <div class="input-group">
          <input type="text" name="password" v-model="credentials.email" :class="{'is-invalid': error.email}"/>
          <label class="control-label">{{'auth.email' | translate}}</label><i class="bar"></i>
          <span class="invalid-feedback" v-if="error.email">
            {{ error.email[0] }}
          </span>
        </div>
      </div>
      <div class="form-group mt-5">
        <div class="input-group">
          <input type="password" name="password" v-model="credentials.password" :class="{'is-invalid': error.password}"/>
          <label class="control-label">{{'auth.password' | translate}}</label><i class="bar"></i>
          <span class="invalid-feedback" v-if="error.password">
            {{ error.password[0] }}
          </span>
        </div>
      </div>
      <div class="form-group mt-5">
        <div class="input-group">
          <input type="password" name="password_confirmation" v-model="credentials.password_confirmation" />
          <label class="control-label" >Password confirmation</label><i class="bar"></i>
        </div>
      </div>
      <div class="d-flex flex-column flex-lg-row align-items-center justify-content-center down-container">
        <Button classes="btn btn-primary" :loading="loading">
          Reset password
        </Button>
      </div>
      <p class="text-center" style="margin-top: 15px;">
        Already have an account? 
        <router-link class="link" :to="{name: 'login'}">
          {{ 'auth.login' | translate }}
        </router-link>
      </p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'reset-password',

  data () {
    return {
      credentials: {
        token: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
      error: {}
    }
  },

  mounted() {
    this.credentials.token = this.$route.query.token
  },

  methods: {
    resetPassword () {
      this.error = {}

      this.$store.dispatch('auth/resetPassword', this.credentials)
        .then(() => {
          this.$router.push({name: 'login'})
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            this.error = err.response.data.errors
          }
        })
    }
  },

  computed: {
    loading () { 
      return this.$store.state.auth.loading
    }
  }
}
</script>

<style lang="scss">
  .login {
    width: 21.375rem;
    @include media-breakpoint-down(md) {
      width: 100%;
      padding-right: 2rem;
      padding-left: 2rem;
      .down-container {
        .link {
          margin-top: 2rem;
        }
      }
    }

    h2 {
      text-align: center;
    }

    .link {
      color: #3578c6;
    }
  }
</style>
